export const cities = {
  Podgorica: [
    "Iza Delte",
    "Blok 5",
    "Blok 6",
    "Blok 9",
    "Centar",
    "City Kvart",
    "Cvijetin Brijeg",
    "Dahna",
    "Dalmatinska",
    "Donja Gorica",
    "Drač",
    "Autobuska",
    "Gintaš",
    "Gornja Gorica",
    "Ibričevina",
    "Ispod Gorice",
    "Konik",
    "Lepa Kata",
    "Ljubović",
    "Malo Brdo",
    "Masline",
    "Momišići",
    "Pejton",
    "Pobrežje",
    "Preko Morače",
    "Kruševac",
    "Sadine",
    "Stara Varoš",
    "Stari Aerodrom",
    "Tološi",
    "Tuški put",
    "Vranići",
    "Zabjelo",
    "Zagorič",
    "Zelenika",
    "Zlatica",
    "Balabani",
    "Beri",
    "Berislavci",
    "Bioče",
    "Botun",
    "Cijevna",
    "Doljani",
    "Donji Kokoti",
    "Farmaci",
    "Golubovci",
    "Goričani",
    "Gornji Kokoti",
    "Gostilj",
    "Grbavci",
    "Kakaricka Gora",
    "Lekići",
    "Ljajkovići",
    "Mahala",
    "Mareza",
    "Mataguži",
    "Mitrovići",
    "Mojanovići",
    "Murtovina",
    "Ponari",
    "Pričelje",
    "Rogami",
    "Srpska",
    "Velje Brdo",
    "Vranjina",
    "Vranjske njive",
    "Vrela Ribnička",
    "Vukovci",
    "Central Point",
    "Master kvart",
    "City Kej",
    "Vezirov most",
    "Piperi",
    "Dajbabe",
    "Kučka korita",
    "Zeta",
    "Orahovo",
    "Barutana",
    "Ostalo",
  ],
  Andrijevica: [],
  Bar: [],
  Berane: [],
  "Bijelo Polje": [],
  Budva: [
    "Babilonija",
    "Babin Do",
    "Bečići",
    "Bjeli Do",
    "Blizikuće",
    "Boreti",
    "Brajići",
    "Buljarica",
    "Čelobrdo",
    "Centar",
    "Drobnići",
    "Dubovica",
    "Golubovina",
    "Gospoštine",
    "Grbalj",
    "Lapičići",
    "Lazi",
    "Maini",
    "Markovići",
    "Petrovac",
    "Pobori",
    "Podkošljun",
    "Podmaine",
    "Pržno",
    "Rafailovići",
    "Reževići",
    "Rozino",
    "Stari Grad",
    "Sveti Stefan",
    "Tudorovići",
    "Velji Vinogradi",
    "Zavala",
    "Maine",
    "Podličak",
    "Krstac",
    "Kamenovo",
  ],
  Cetinje: [],
  Danilovgrad: [],
  Gusinje: [],
  "Herceg Novi": [],
  Kolašin: [],
  Kotor: [],
  Mojkovac: [],
  Nikšić: [],
  Petnjica: [],
  Plav: [],
  Plužine: [],
  Pljevlja: [],
  Rožaje: [],
  Šavnik: [],
  Tivat: [],
  Tuzi: [],
  Ulcinj: [],
  Zeta: [],
  Žabljak: [],
};
