import NewAccommodation from './pages/NewAccommodation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import Accommodations from './pages/Accommodations';
import Accommodation from './pages/Accommodation';
import Login from './pages/login';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/routes/protectedRoute';
import PersistLogin from './components/routes/PersistLogin';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Login />} />
                        <Route element={<PersistLogin />}>
                            <Route element={<ProtectedRoute />}>
                                <Route path='/new-accommodation' element={<NewAccommodation />} />
                                <Route path='/accommodations' element={<Accommodations />} />
                                <Route path='/accommodations/:id' element={<Accommodation />} /> </Route>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>

    );
}

export default App;
