import { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { cities } from '../services/constants/cities';
import { AccommodationType, CharacteristicType, FileType, Type } from '../services/types/types';
import classes from '../style/newAccommodation.module.scss'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import useAreas from '../hooks/useAreas';
import GalleryCarousel from '../components/GalleryCarousel';
import useApiService from '../services/services';
import { BeatLoader } from 'react-spinners';
import CoverPhoto from '../components/CoverPhoto';

const override: CSSProperties = {
  display: "block",
  margin: "0 6px",
  borderColor: "red",
};

const Accommodation: FC = () => {
  const navigate = useNavigate()
  const apiService = useApiService();
  const { id } = useParams()
  const [accommodation, setAccommodation] = useState<AccommodationType | null>(null)
  const [types, setTypes] = useState<Type[]>([]);
  const [characteristics, setCharacteristics] = useState<CharacteristicType[]>([]);
  const [characteristicsIds, setCharacteristicsIds] = useState<number[]>([])
  const [hidden, setHidden] = useState("0")
  const [hiddenChecked, setHiddenChecked] = useState(false)
  const filteredAreaItems = useAreas(accommodation?.city || "")
  const [accommodationFiles, setAccommodationFiles] = useState<FileType[]>([]);
  const [uploadFileList, setUploadFileList] = useState<File[]>([]);
  const [coverPhoto, setCoverPhoto] = useState<File | string | null>(null);
  const [imagesToBeDeleted, setImagesToBeDeleted] = useState<number[]>([]);
  const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false)
  const [isLoadingEstitor, setIsLoadingEstitor] = useState(false)
  const [isEstitorBtnDisabled, setIsEstitorBtnDisabled] = useState(true)

  useEffect(() => {
    (async () => {
      if (!id) return

      const accommodation = await apiService.getAccommodation(+id)
      if (accommodation.status !== 200) return;
      if (accommodation.data.data!.hidden === 1) {
        setHidden("1")
        setHiddenChecked(true)
      }
      const typeResponse = await apiService.getTypes();
      if (typeResponse.status !== 200) return;
      const characteristicsResponse = await apiService.getCharacteristics();
      if (characteristicsResponse.status !== 200) return;

      setAccommodation(accommodation.data.data!)
      setAccommodationFiles(accommodation.data.data!.files)
      setCharacteristicsIds(accommodation.data.data!.characteristics.map(({ id }) => id))
      setTypes(typeResponse.data.types);
      setTitle(accommodation.data.data!.title);
      setCharacteristics(characteristicsResponse.data.characteristics);
      setCoverPhoto(accommodation.data.data?.featuredImage || null)
      if (!accommodation.data.data?.estitor || accommodation.data.data.dirtyEstitor) {
        setIsEstitorBtnDisabled(false)
      }
    })();
  }, [id]);

  const toggleHidden = () => {
    setHidden(hidden === "0" ? "1" : "0");
  }

  const handleCharacteristicsArray = (id: number) => {
    let array: number[] = characteristicsIds;
    if (characteristicsIds.includes(id)) {
      const index = array.indexOf(id);
      array.splice(index, 1);
    } else {
      array.push(id)
    }
    setCharacteristicsIds(array)
  }

  const handleDeleteItem = async (id: number) => {
    setIsLoadingDeletion(true)

    Swal.fire({
      title: 'Da li ste sigurni?',
      text: "Nije moguce povratiti obrisani smjestaj!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da, obrisi',
      cancelButtonText: 'Ne'
    }).then(async (result) => {
      if (!result.isConfirmed) {
        return
      }
      try {
        const deleteItemResponse = await apiService.deleteAccomodation(id)

        if (deleteItemResponse.status === 200) {
          let estitorMessage = ""
          if (deleteItemResponse.data.estitor === 1) {
            estitorMessage = "i uspjesno obrisano sa estitora"
          } else {
            estitorMessage = "i neuspjesno obrisano sa estitora"
          }
          Swal.fire('Obrisano!', `Nekretnina - ${id} je uspješno obrisana ` + estitorMessage, 'success');
          setTimeout(() => {
            navigate('/accommodations')
          }, 300);
        }
      } catch (error) {
        Swal.fire('Neuspjesno!', `Neuspjesno brisanje`, 'error');

      }
      finally {
        setIsLoadingDeletion(false)
      }

    })

  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (coverPhoto === null) {
      Swal.fire('Neuspjesno!', `Naslovna slika je obavezna`, 'error');
      return;
    }
    setIsLoading(true)
    if (!accommodation) return
    const { id, status, type, area, city, description, rooms, bathrooms, furnished, quadrature, price, owner, owner_number, floor, note } = accommodation
    const data = new FormData()
    data.append("status", status.toString())
    data.append("typeId", type.type_id.toString());
    data.append("area", area)
    data.append("city", city)
    data.append("description", description)
    data.append("rooms", rooms.toString())
    data.append("bathrooms", bathrooms.toString())
    data.append("furnished", furnished.toString())
    data.append("hidden", hidden)
    data.append("quadrature", quadrature.toString())
    data.append("price", price)
    data.append("owner", owner)
    data.append("owner_number", owner_number)
    data.append("title", title)
    data.append("characteristicsIds", JSON.stringify(characteristicsIds));

    if (coverPhoto && typeof (coverPhoto) !== "string") {
      data.append("file", coverPhoto)
    }

    if (floor) {
      data.append("floor", floor.toString())
    }
    if (note) {
      data.append("note", note)
    }
    try {
      const accommodationsResponse = await apiService.updateAccommocation(id, data);

      if (accommodationsResponse.status === 200) {

        if (imagesToBeDeleted.length > 0) {
          imagesToBeDeleted.forEach(async (image) =>
            await apiService.deleteFile(id, image)
          )
        }
        if (uploadFileList.length > 0) {
          const formData = new FormData()
          uploadFileList.forEach((file) => formData.append('files', file))
          await apiService.uploadFiles(accommodation.id, formData)
        }
        const { data: dateRes } = accommodationsResponse.data;
        Swal.fire('Sačuvano!', `Nekretnina - ${dateRes!.id} je uspješno ažurirana `, 'success');
        setIsEstitorBtnDisabled(false)
      }
    } catch (error) {
      Swal.fire('Neuspjesno!', `Neuspjesno mjenjanje`, 'error');

    }
    finally {
      setIsLoading(false)
    }
  }

  const handleDeleteImage = async (fileId: number) => {
    setImagesToBeDeleted((images) => [...images, fileId])
    setAccommodationFiles((files) => files.filter((file) => file.id !== fileId))
  }
  const handleRemovePhotoFromUpload = async (fileName: string) => {
    setUploadFileList((fileList) => fileList.filter(file => file.name !== fileName))
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = e.target.files
      setUploadFileList((fileList) => [...fileList, ...newFiles])
    }
  };

  const handleCoverPhoto = async (newFile: File) => {
    setCoverPhoto(newFile);
  };

  const handleDeleteCoverPhoto = () => {
    setCoverPhoto(null)
  }

  const handleSendToEstitor = async (accommodationId: number) => {
    setIsLoadingEstitor(true)
    try {
      const estitorCreate = await apiService.createAdOnEstitor(accommodationId)
      if (estitorCreate.data.success === 1) {
        setIsEstitorBtnDisabled(true)
        Swal.fire('Uspješno dodato!', 'Nekretnina je dodata na estitor', 'success');
      } else {
        Swal.fire('Neuspješno dodato!', `Neuspjesno dodato na estitor`, 'error');
      }

    } catch (error) {
      console.log(error)
      Swal.fire('Neuspješno dodato!', `Neuspjesno dodato na estitor`, 'error');
    } finally {
      setIsLoadingEstitor(false)
    }
  }

  return (
    <>
      {accommodation &&
        <Form id='form-accommodation' onSubmit={handleSubmit} className={classes.formNewAccommodation}>
          <h2 className="header">Ažuriranje nekretnine</h2>
          <hr />
          <Form.Group
            className="mb-3"
            controlId="title"
          >
            <Form.Label>Naslov nekretnine</Form.Label>
            <Form.Control onChange={(e) => setTitle(e.target.value)} value={title} required type="text" />
          </Form.Group>
          <Form.Label htmlFor='status'>Status</Form.Label>
          <Form.Select defaultValue={accommodation.status} onChange={(e) => setAccommodation({ ...accommodation, status: e.target.value })} className="mb-3" id="status" required>
            <option value="Izdavanje">Izdavanje</option>
            <option value="Prodaja">Prodaja</option>
          </Form.Select>
          <Form.Label htmlFor='type'>Tip nekretnine</Form.Label>
          <Form.Select defaultValue={accommodation.type.type_id} onChange={(e) => setAccommodation({ ...accommodation, type: { name: types.find(t => t.type_id === +(e.target.value))!.name, type_id: +(e.target.value) } })} className="mb-3" id="type" required>
            {types.length > 0 &&
              types.map((type) => (
                <option key={type.type_id} value={type.type_id}>
                  {type.name}
                </option>
              ))}
          </Form.Select>
          <Form.Group className="mb-3">
            <Form.Label htmlFor='city'>Grad</Form.Label>
            <Form.Control autoComplete='off' value={accommodation.city} onChange={(e) => setAccommodation({ ...accommodation, city: e.target.value })} list="cities" type="text" required />
            <datalist id="cities">
              {
                Object.keys(cities).map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))
              }
            </datalist>
          </Form.Group>
          <Form.Group className="mb-3" controlId="area">
            <Form.Label>Naselje</Form.Label>
            <Form.Control autoComplete='off' defaultValue={accommodation.area} onChange={(e) => setAccommodation({ ...accommodation, area: e.target.value })} required type="text" list="filteredAreaItems" placeholder="Naselje.." />
            <datalist id="filteredAreaItems">
              {
                filteredAreaItems.map((area) => (
                  <option key={area} value={area}>
                    {area}
                  </option>
                ))}
            </datalist>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="description"
          >
            <Form.Label>Opis nekretnine</Form.Label>
            <Form.Control defaultValue={accommodation.description} onChange={(e) => setAccommodation({ ...accommodation, description: e.target.value })} required as="textarea" rows={3} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label>Cijena nekretnine</Form.Label>
            <Form.Control defaultValue={accommodation.price} onChange={(e) => setAccommodation({ ...accommodation, price: e.target.value })} required type="number" step="0.01" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="quadrature">
            <Form.Label>Kvadratura</Form.Label>
            <Form.Control defaultValue={accommodation.quadrature} onChange={(e) => setAccommodation({ ...accommodation, quadrature: +(e.target.value) })} required type="number" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="rooms">
            <Form.Label>Broj soba</Form.Label>
            <Form.Control defaultValue={accommodation.rooms} onChange={(e) => setAccommodation({ ...accommodation, rooms: +(e.target.value) })} required type="number" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="bathrooms">
            <Form.Label>Broj kupatila</Form.Label>
            <Form.Control defaultValue={accommodation.bathrooms} onChange={(e) => setAccommodation({ ...accommodation, bathrooms: +(e.target.value) })} required type="number" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="floor">
            <Form.Label>Sprat</Form.Label>
            <Form.Control defaultValue={accommodation.floor} onChange={(e) => setAccommodation({ ...accommodation, floor: +(e.target.value) })} required type="number" />
          </Form.Group>
          <Form.Label htmlFor='furnished'>Opremljenost nekretnine</Form.Label>
          <Form.Select defaultValue={accommodation.furnished} onChange={(e) => setAccommodation({ ...accommodation, furnished: parseFloat(e.target.value) })} id="furnished" className="mb-3" required>
            <option value="1">Namješten</option>
            <option value="0.5">Polunamješten</option>
            <option value="0">Nenamješten</option>
          </Form.Select>
          <hr />
          <Form.Group className="mb-3">
            <Form.Label>Naslovna slika</Form.Label>
            <br />
            <CoverPhoto onDelete={handleDeleteCoverPhoto} onChange={handleCoverPhoto} coverPhoto={coverPhoto} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Izaberi ostale slike</Form.Label>
            <br />
            <input id="files" type="file" multiple onChange={(e) => handleFileChange(e)} />
          </Form.Group>
          {(accommodationFiles.length > 0 || uploadFileList.length > 0) &&
            <GalleryCarousel
              files={accommodationFiles}
              uploadedFiles={uploadFileList}
              onDeleteFile={handleDeleteImage}
              onDeleteUploaded={handleRemovePhotoFromUpload} />
          }
          <hr />
          <div className={classes.radioButtons}>
            {characteristics.length > 0 &&
              characteristics.map((characteristic) => (
                <Form.Check onChange={() => handleCharacteristicsArray(characteristic.id)}
                  className="m-3 flex-fill"
                  type="checkbox"
                  id={characteristic.id.toString()}
                  key={characteristic.id}
                  label={characteristic.name}
                  defaultChecked={characteristicsIds.includes(characteristic.id)}
                />
              ))}
          </div>
          <hr />
          <h5 className="mb-3">Podaci o vlasniku</h5>
          <Form.Group className="mb-3" controlId="owner">
            <Form.Label>Ime i prezime vlasnika</Form.Label>
            <Form.Control defaultValue={accommodation.owner} onChange={(e) => setAccommodation({ ...accommodation, owner: e.target.value })} required type="text" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="owner_number">
            <Form.Label>Broj telefona vlasnika</Form.Label>
            <Form.Control defaultValue={accommodation.owner_number} onChange={(e) => setAccommodation({ ...accommodation, owner: e.target.value })} required type="tel" />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="note"
          >
            <Form.Label>Bilješka</Form.Label>
            <Form.Control defaultValue={accommodation.note} onChange={(e) => setAccommodation({ ...accommodation, note: e.target.value })} as="textarea" rows={3} />
          </Form.Group>
          <hr />
          <Form.Check onChange={toggleHidden} defaultChecked={hiddenChecked} defaultValue={accommodation.hidden}
            className="mb-3"
            type="switch"
            id="hidden"
            label="Arhiviraj nekretninu"
          />
          <hr />
          <div className='text-end'>{accommodation.agent?.name} {accommodation.agent?.surname}</div>

          <div className='row mt-5 mx-auto'>
            <Button variant='success' className="col-md-3 col-12 m-2 mt-3" type="submit">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isLoading ?
                  <BeatLoader
                    color={"#ffffff"}
                    loading={isLoading}
                    cssOverride={override}
                    size={14}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> : "Sačuvaj"}
              </div>
            </Button>
            <Button variant='primary' className="col-md-3 col-12 m-2 mt-3" onClick={() => handleSendToEstitor(accommodation.id)} disabled={isEstitorBtnDisabled}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isLoadingEstitor ?
                  <BeatLoader
                    color={"#ffffff"}
                    loading={isLoadingEstitor}
                    cssOverride={override}
                    size={14}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> : "Estitor"}
              </div>
            </Button>
            <Button variant='danger' className="col-md-3 col-12 m-2 mt-3" onClick={() => handleDeleteItem(accommodation.id)}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isLoadingDeletion ?
                  <BeatLoader
                    color={"#ffffff"}
                    loading={isLoadingDeletion}
                    cssOverride={override}
                    size={14}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  /> : "Obriši"}
              </div>
            </Button>
          </div>
        </Form>
      }
    </>
  );
};

export default Accommodation;
