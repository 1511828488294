import { FC, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { BsTrash } from 'react-icons/bs';
import { FILES_URL } from '../services/constants/endpoints';
import { FileType, isFileType } from '../services/types/types';
import classes from '../style/galleryCarousel.module.scss'

interface IGalleryCarousel {
  files: FileType[]
  uploadedFiles: File[],
  onDeleteUploaded: (filename: string) => void
  onDeleteFile: (fileId: number) => void
}

const GalleryCarousel: FC<IGalleryCarousel> = ({ files, uploadedFiles, onDeleteUploaded, onDeleteFile }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <AliceCarousel
      responsive={{
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
      }}
      items={
        [...files, ...uploadedFiles].map((file) => isFileType(file) ? (
          <div key={file.id}>
            <div className={classes.carouselImage}>
              <div className={classes.trash} onClick={() => onDeleteFile(file.id)}>
                <BsTrash size={30} color='red' />
              </div>
              <img style={{ width: 300, height: 300 }} src={FILES_URL + file.url} alt='' />
            </div>
          </div>
        ) :
          (
            <div key={file.name}>
              <div className={classes.carouselImage}>
                <div className={classes.trash} onClick={() => onDeleteUploaded(file.name)}>
                  <BsTrash size={30} color='red' />
                </div>
                <img style={{ width: 300, height: 300 }} src={URL.createObjectURL(file)} alt='' />
              </div>
            </div>
          ))
      }
      activeIndex={activeSlideIndex}
      onSlideChange={(e) => setActiveSlideIndex(e.slide)}
    />
  )
}

export default GalleryCarousel