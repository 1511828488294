import { FC, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import useLogout from '../../services/logout';
import classes from './mainLayout.module.scss';
import { useLocation } from 'react-router-dom';


const MainLayout: FC = () => {
    const [activeNavItem, setActiveNavItem] = useState(1)
    const logout = useLogout();
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/accommodations':
                setActiveNavItem(1);
                break;
            case '/new-accommodation':
                setActiveNavItem(2);
                break;
            default:
                setActiveNavItem(1);
        }
    }, [location]);

    return (
        <>
            <input
                type="checkbox"
                name="MenuToggle"
                className={classes.menuToggle}
                id="MenuToggle"
            />
            <aside className={classes.sidebar}>
                <nav>
                    <Link to="#" className={classes.logo}>
                        <img src="/images/logo2.png" alt="" />
                    </Link>
                    <div className={classes.navItems}>
                        <Link onClick={() => setActiveNavItem(1)} to="/accommodations" className={`${activeNavItem === 1 ? classes.active : ''}`}>Nekretnine</Link>
                        <Link onClick={() => setActiveNavItem(2)} to="/new-accommodation" className={`${activeNavItem === 2 ? classes.active : ''}`}>
                            Nova nekretnina
                        </Link>
                        <Link onClick={() => { logout(); setActiveNavItem(1); }} to='/'>Odjava</Link>
                    </div>
                </nav>
            </aside>
            <div className={classes.right}>
                <label htmlFor="MenuToggle" className={classes.toggleIcon}>
                    <span className={`${classes.line} ${classes.line1}`}></span>
                    <span className={`${classes.line} ${classes.line3}`}></span>
                    <span className={`${classes.line} ${classes.line2}`}></span>
                </label>
                <div className={classes.content}>
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default MainLayout;
