import { FC } from 'react';

interface EstitorIconProps {
  fill: string;
}

const EstitorIcon: FC<EstitorIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="60 20 500 600">
      <path fill={fill} d="m472.3 494.7h-327.1v-229l163.6-98.1 163.6 98.1v229h-0.1zm-159.9-248.8c-23.5 0-44.7 4.8-62.9 14.3s-32.5 23.3-42.6 41-15.2 39.4-15.2 64.5 5.1 46.8 15.2 64.5 24.6 31.5 43.1 41 40.7 14.3 66.1 14.3c15.9 0 31.3-2.3 45.8-6.9s27.2-11.5 37.8-20.5c10.5-9 18.1-20.3 22.3-33.4l-62.7-20.7c-1.7 4.1-3.8 7.8-6.4 11.1-2.6 3.2-5.6 6-9 8.3s-7.5 4-12.2 5.2c-4.6 1.1-9.9 1.7-15.7 1.7-5.2 0-10.1-0.5-14.5-1.6s-8.5-2.6-12.2-4.7-7-4.9-9.8-8.2c-2.8-3.4-5.3-7.4-7.3-12.1-2.2-5.2-3.8-11.3-4.8-18.2h157.3c0.6-3.7 1.1-8.3 1.4-13.8s0.5-11.1 0.5-16.6c0-21.1-4.5-40.1-13.4-56.4-8.9-16.4-22-29.4-38.9-38.7-16.9-9.4-37.7-14.1-61.9-14.1zm39.8 97.7h-84.2c0.9-5.3 2.1-10.1 3.6-14.3 1.8-4.9 4-9.2 6.6-12.8s5.7-6.6 9.1-8.9c3.5-2.3 7.4-4.1 11.5-5.2 4.2-1.1 8.8-1.7 13.6-1.7 3.4 0 6.7 0.3 9.7 0.9 2.9 0.6 5.7 1.5 8.3 2.8 2.5 1.2 4.9 2.8 7 4.6 2.1 1.9 4 4.1 5.6 6.4 2.4 3.5 4.4 7.7 5.9 12.4 1.6 4.7 2.7 10 3.3 15.8z" />
    </svg>
  )
}

export default EstitorIcon;