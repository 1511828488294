import api from "./axios";
import useApiPrivate from "./privateApi";
import { AccommodationType } from "./types/types";

interface Response<T> {
  data?: T;
  success: number | string;
  total?: number;
  estitor?: string | number;
}

const useApiService = () => {
  const apiPrivate = useApiPrivate();
  class ApiService {
    async getTypes() {
      return await api.get("accommodations/types");
    }

    async getCharacteristics() {
      return await api.get("accommodations/characteristics");
    }

    async getAccommodations(params?: string) {
      return await api.get<Response<AccommodationType[]>>(
        "accommodations?" + (params || "")
      );
    }

    async getAccommodation(id: number) {
      return await api.get<Response<AccommodationType>>("accommodations/" + id);
    }

    async createAccommocation(data: FormData) {
      return await apiPrivate.post("accommodations/", data, {
        headers: {
          "Content-Type": "multiplart/form-data",
        },
      });
    }

    async createAdOnEstitor(id: number) {
      return await apiPrivate.post("accommodations/estitor/create/" + id);
    }

    async deleteAccomodation(id: number) {
      return await apiPrivate.delete<Response<AccommodationType>>(
        `accommodations/${id}`
      );
    }

    async updateAccommocation(id: number, data: FormData) {
      return await apiPrivate.patch<Response<AccommodationType>>(
        `accommodations/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multiplart/form-data",
          },
        }
      );
    }

    async uploadFiles(id: number, data: FormData) {
      return await apiPrivate.post(`accommodations/${id}/upload`, data);
    }

    async deleteFile(accommodationId: number, fileId: number) {
      return await apiPrivate.delete(
        `accommodations/${accommodationId}/files/${fileId}`
      );
    }

    async getIds() {
      return await api.get(`accommodations/ids`);
    }

    async login(data: FormData) {
      return await api.post(`/login`, data);
    }
  }
  return new ApiService();
};

export default useApiService;
