import { useEffect, useState } from "react";
import { cities } from "../services/constants/cities";
import { ICities } from "../services/types/types";

const useAreas = (city: string) => {
  const [filteredAreaItems, setFilteredAreaItems] = useState<string[]>([])

  useEffect(() => {
    setFilteredAreaItems((cities as ICities)[city] || []);
  }, [city])

  return filteredAreaItems
}

export default useAreas
