import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../context/AuthContext";
import useApiService from "../services/services";
import classes from "../style/login.module.scss";


const Login = () => {
    const { setAccessToken } = useAuth()
    const apiService = useApiService();

    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData()
        data.append("email", email.toString())
        data.append("password", password.toString());
        await apiService.login(data).then((response) => {
            if (response.status === 201) {
                localStorage.setItem('refreshToken', response.data.refreshToken)
                setAccessToken(response.data.accessToken)
                navigate('/accommodations')
            }
        }).catch((err) => {
            const message = err.response.data.message
            Swal.fire('Error!', message, 'error');

        }
        )


    }
    return (
        <Form className={`${classes.loginForm} py-4`} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email adresa</Form.Label>
                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Napiši email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Šifra</Form.Label>
                <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Šifra" />
            </Form.Group>
            <Button className="btn btn-secondary w-100" type="submit">
                Potvrdi
            </Button>
        </Form>
    );
};

export default Login;
