import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Login from "../../pages/login";

const ProtectedRoute = () => {
    const { accessToken } = useAuth();
    return (
        !accessToken ? <Login /> : <Outlet />
    )
}

export default ProtectedRoute;