import ReactPaginate from 'react-paginate';
import classes from '../style/pagination.module.scss'
import { useMediaQuery } from "@uidotdev/usehooks";

interface PaginationProps {
    onChangePage: (page: number) => void
    total: number
    itemsPerPage: number
    currentPage: number
}

export function PaginatedItems({ onChangePage, total, itemsPerPage, currentPage }: PaginationProps) {
    const pageCount = Math.ceil(total / itemsPerPage);

    const isSmallDevice = useMediaQuery("only screen and (max-width : 540px)");

    const handlePageClick = async (event: { selected: number; }) => {
        onChangePage(event.selected)
    };

    let pageRangeDisplayed;
    let marginPagesDisplayed;

    if (isSmallDevice) {
        pageRangeDisplayed = 1; 
        marginPagesDisplayed = 1;
    } else {
        pageRangeDisplayed = 2; 
        marginPagesDisplayed = 3;
    }

    return (
        <div className={classes.paginationBttns}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={marginPagesDisplayed}
                pageCount={pageCount}
                previousLabel="<"
                activeClassName={classes.active}
                forcePage={currentPage}
            />
        </div>
    );
}
