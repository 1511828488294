import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { API_URL } from "./constants/endpoints";
import useRefreshToken from "./refreshToken";

interface MyResponse extends InternalAxiosRequestConfig {
  sent?: boolean;
}

const useApiPrivate = () => {
  const refreshToken = useRefreshToken();
  const { accessToken } = useAuth();

  const apiPrivate = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-type": "application/json",
    },
  });
  const requestIntercept = apiPrivate.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      if (accessToken && !config.headers["Authorization"]) {
        config.headers!.Authorization = `Bearer ${accessToken}`;
      }
      const match = config!.url!.match(/accommodations\/(\d+)\/upload/);
      if (match) {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const responseIntercept = apiPrivate.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const prevRequest: MyResponse | undefined = error.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest!.sent = true;

        const { accessToken } = await refreshToken();
        prevRequest!.headers["Authorization"] = `Bearer ` + accessToken;

        const match = prevRequest!.url!.match(/accommodations\/(\d+)\/upload/);
        if (match) {
          prevRequest!.headers["Content-Type"] = "multipart/form-data";
        }

        return apiPrivate(prevRequest!);
      }
      return Promise.reject(error);
    }
  );
  useEffect(() => {
    return () => {
      // This cleanup function runs when the component using the useApiPrivate hook unmounts
      apiPrivate.interceptors.request.eject(requestIntercept);
      apiPrivate.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return apiPrivate;
};

export default useApiPrivate;
