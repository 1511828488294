export type Type = {
  name: string;
  type_id: number;
};

export type CharacteristicType = {
  id: number;
  name: string;
};

export type UserType = {
  id: number;
  name: string;
  surname: string;
  phone: string;
  email: string;
  password: string;
  refreshToken: string;
  created_at: string;
  updated_at: string;
};

export type FileType = {
  id: number;
  url: string;
};

export function isFileType(object: any): object is FileType {
  return "id" in object;
}

export type AccommodationType = {
  id: number;
  title: string;
  description: string;
  city: string;
  area: string;
  status: string;
  furnished: number;
  rooms: number;
  bathrooms: number;
  quadrature: number;
  price: string;
  floor: number;
  note: string;
  owner: string;
  owner_number: string;
  hidden: number;
  created_at: string;
  updated_at: string;
  characteristics: CharacteristicType[];
  type: Type;
  agent: UserType;
  files: FileType[];
  featuredImage: string;
  estitor: boolean;
  dirtyEstitor: boolean
};

export type PostNewAccomodation = {
  description: string;
  city: string;
  area: string;
  status: string;
  furnished: string;
  rooms: string;
  bathrooms: string;
  quadrature: string;
  price: string;
  floor?: string;
  note?: string;
  owner?: string;
  owner_number?: string;
  hidden: string;
  characteristicsIds?: string;
  typeId: number;
};

export interface ICities {
  [key: string]: string[];
}
