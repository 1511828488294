import { FC, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import useRefreshToken from '../../services/refreshToken'
import { FaSpinner } from 'react-icons/fa';


const PersistLogin: FC = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const navigate = useNavigate()

    const { accessToken, setAccessToken } = useAuth()

    useEffect(() => {
        if (accessToken !== null) {
            setIsLoading(false)
            return;
        }

        const verifyRefreshToken = async () => {
            try {
                const res = await refresh()
                setAccessToken(res.accessToken);

            } catch (err) {
                navigate('/login')
            } finally {
                setIsLoading(false)
            }
        }

        !accessToken ? verifyRefreshToken() : setIsLoading(false)
    }, [accessToken, navigate, refresh, setAccessToken])

    return isLoading ? <div className="loader"> <FaSpinner size={30} className="spin" /></div> : <Outlet />
}

export default PersistLogin
