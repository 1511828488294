import { useAuth } from "../context/AuthContext";
import api from "./axios";

const useRefreshToken = () => {
  const { setAccessToken } = useAuth();
  const refreshToken = async () => {
    try {
      const response = await api.get(`/refresh`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refreshToken"),
        },
      });
      setAccessToken(response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      return { accessToken: response.data.accessToken };
    } catch (err) {
      console.log(err);
      window.open("/", "_blank");
      return { accessToken: null };
    }
  };

  return refreshToken;
};

export default useRefreshToken;
