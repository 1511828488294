import { createContext, ReactNode, useContext, useState } from "react";

type IContextData = {
    accessToken: string | null,
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>
}

export const AuthContext = createContext<IContextData>({ accessToken: '', setAccessToken: () => void 0 })

export function useAuth() {
    return useContext(AuthContext);
}


export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [accessToken, setAccessToken] = useState(() => localStorage.getItem('accessToken') || null)

    const contextData = {
        accessToken: accessToken,
        setAccessToken: setAccessToken
    }

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}