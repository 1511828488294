import axios from "axios";
import { API_URL } from "./constants/endpoints";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export default api;
