import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import api from "./axios";

const useLogout = () => {
    const navigate = useNavigate();
    const { accessToken, setAccessToken } = useAuth();
    const logout = async () => {
        try {
            await api.get(`/logout`, { headers: { Authorization: "Bearer " + accessToken } });
            setAccessToken(null);
            localStorage.removeItem("refreshToken");
        } catch (err) {
            console.log(err);
        } finally {
            navigate("/");
        }
    };

    return logout;
};

export default useLogout;
