import { ChangeEvent, FC } from "react"
import { BsTrash } from "react-icons/bs"
import { FILES_URL } from "../services/constants/endpoints"
import classes from '../style/galleryCarousel.module.scss'

interface CoverPhotoProps {
  coverPhoto: File | string | null
  onChange: (file: File) => void
  onDelete: () => void
}

const CoverPhoto: FC<CoverPhotoProps> = ({ coverPhoto, onChange, onDelete }) => {

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      const newFile = e.target.files[0];
      onChange(newFile);
    }
  }

  return (
    <>
      <input
        id="file"
        type="file"
        onChange={(e) => handleOnChange(e)}
      />
      {coverPhoto && (
        <div>
          <div className={classes.carouselImage}>
            <div className={classes.trash} onClick={onDelete}>
              <BsTrash size={30} color='red' />
            </div>
            {typeof (coverPhoto) === "string" && <img style={{ width: 300, height: 300 }} src={FILES_URL + coverPhoto} alt='' />}
            {typeof (coverPhoto) !== "string" && <img style={{ width: 300, height: 300 }} src={URL.createObjectURL(coverPhoto)} alt='' />}
          </div>
        </div>
      )
      }
    </>
  )
}

export default CoverPhoto